import { SET_CONFIG, SET_DATA_STAFF, SET_DATE_UPDATE, SET_REFRESH_PAGE, SET_RESET_DATA } from '../type';

const INITIAL_STATE = {
        config: [],
        dataStaff: [],
        isRefresh: true
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONFIG:
            return {
                ...state,
                config: action.payload,
            };
        case SET_DATA_STAFF:
            return {
                ...state,
                dataStaff: action.payload,
            };
        case SET_DATE_UPDATE:
            return {
                ...state,
                date: action.payload,
            };
        case SET_REFRESH_PAGE:
            return {
                ...state,
                isRefresh: action.payload
            }
        case SET_RESET_DATA: 
            return {
                ...state,
                config: [],
                dataStaff: [],
                isRefresh: true
            }        
        default:
            return state;

    }

};

export default reducer;