import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// screens 

import HomeScreen from './screens/home/homescreen';
import ConfigScreen from './screens/config/configscreen';
import DetailScreen from './screens/detail/detailscreen';



const RootApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen/>}/>
        <Route path="/config" element={<ConfigScreen/>}/>
        <Route path="/detail" element={<DetailScreen/>}/>
      </Routes>
  </BrowserRouter>
  );
};

export default RootApp;