import { SET_CONFIG, SET_DATA_STAFF, SET_DATE_UPDATE, SET_REFRESH_PAGE, SET_RESET_DATA } from "../type"

export const setConfig = (payload) => {
    return {
        type: SET_CONFIG,
        payload: payload
    }
}

export const setDataStaff = (payload) => {
    return {
        type: SET_DATA_STAFF,
        payload: payload
    }
}

export const setDateUpdate = (payload) => {
    return {
        type: SET_DATE_UPDATE,
        payload: payload
    }
}

export const setRefreshPage = (payload) => {
    return {
        type: SET_REFRESH_PAGE,
        payload: payload
    }
}
export const setResetData = () => {
    return {
        type: SET_RESET_DATA
    }
}