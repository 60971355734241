import React, { useState, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfig, setDataStaff, setDateUpdate, setRefreshPage, setResetData } from '../../redux/action/actions';
import { Button, message, Spin, Table, Tooltip, Upload, DatePicker } from 'antd';
import { UploadOutlined, ImportOutlined, EyeOutlined, CalculatorOutlined } from '@ant-design/icons';
import '../home/home.css';
import logo_fami from '../../assets/logo_fami.png';
import download_ios from '../../assets/ic_download_ios.jpeg';
import download_android from '../../assets/ic_download_android.png';
import { UploadProps } from 'antd';
import * as XLSX from 'xlsx';
import { formatNumber } from '../../helper/formatnumb';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const HomeScreen = memo(() => {
    const dispatch = useDispatch();
    const { RangePicker } = DatePicker;
    const reducer = useSelector(state => state?.rootReducer)
    const dataStaff = useSelector(state => state?.rootReducer?.dataStaff);
    const [listStaff, setListStaff] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('Đang tải dữ liệu');
    const [fromTime, setFromTime] = useState();
    const [toTime, setToTime] = useState();
    const dateFormat = 'YYYY/MM/DD';
    console.log("reducer", reducer);
    const navigate = useNavigate();
    const handleClickViewDetail = (data) => {
        console.log("dataclick", data);
        navigate("/detail", {
            state: data
        })
    }
    const columns = [
        {
            title: 'STT',
            key: 'index',
            render: (text, record) => data.indexOf(record) + 1,
        },
        {
            title: 'Nhân viên',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Doanh thu',
            dataIndex: 'revenue',
            key: 'revenue',
        },
        {
            title: 'Thu nhập',
            dataIndex: 'bonus',
            key: 'bonus',
        },
        {
            title: 'Xem chi tiết',
            key: 'action',
            render: (_, record) => (
                <Tooltip title="Xem chi tiết">
                    <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => handleClickViewDetail(record)} />
                </Tooltip>
            )
        },
    ]

    const handleGetDataFromKiot = () => {
        if (!fromTime || !toTime) {
            return;
        }
        setLoading(true);
        setLoading('Đang tải dữ liệu');
        console.log();
        fetch(`https://script.google.com/macros/s/AKfycbw8bHvo9ENPxRu2xMjydhXFTB3QiTvLAp8JdYhhtAc5wbO9-mWmYdwbz6tEm241wVkNUQ/exec?type=GET_INVOICE&fromTime=${fromTime}&toTime=${toTime}`, {
            method: 'POST',
            mode: 'cors'
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data kiot", data);

                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
                setLoading(false);
            });
    }
    // const getToken = async () => {
    //     let payload = {
    //         "client_id": "24398b71-381b-4b87-a969-05579e5256e1",
    //         "client_secret": "B51465CC8B6AF1D1D45667C13B6B43D067BF19A0",
    //         "grant_type": "client_credentials",
    //         "scopes": "PublicApi.Access"
    //     }
    //     let formData = new URLSearchParams();
    //     formData.append("client_id", "24398b71-381b-4b87-a969-05579e5256e1");
    //     formData.append("client_secret", "B51465CC8B6AF1D1D45667C13B6B43D067BF19A0");
    //     formData.append("grant_type", "client_credentials");
    //     formData.append("scopes", "PublicApi.Access");

    //     const res = await fetch('https://id.kiotviet.vn/connect/token', {
    //         method: 'POST',
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded",
    //             "Access-Control-Allow-Origin": "*",
    //         },
    //         body: formData.toString()
    //     });
    //     const data = await res.json();
    //     console.log("dataatatt", data);

    // }
    const getConfig = () => {
        setLoading(true);
        setLoading('Đang tải dữ liệu');
        fetch('https://script.google.com/macros/s/AKfycbw8bHvo9ENPxRu2xMjydhXFTB3QiTvLAp8JdYhhtAc5wbO9-mWmYdwbz6tEm241wVkNUQ/exec?type=GET_CONFIG', {
            method: 'POST',
            mode: 'cors'
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                dispatch(setRefreshPage(false));
                dispatch(setConfig(data));
                if (data) {
                    setListProduct([...data?.listPrice]);
                    setListStaff([...data?.listStaff]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });

    }
    const props: UploadProps = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            setLoading(true);
            setMessage('Đang tính toán dữ liệu');
            console.log('beforeUpload', file)
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                console.log("dataParse", dataParse)
                let dataTable = [];
                listStaff.forEach(staff => {
                    let listBillByStaff = dataParse.filter(bill => bill[19] === staff.name && staff?.branch?.indexOf(bill[0]) > -1).map(bill => {
                        let product = listProduct.find(prod => {
                            return prod.code === bill[49];
                        });
                        let discount = 0;
                        if (product) {
                            if (bill[59] >= product?.price4) {
                                discount = product?.discount4;
                            }
                            else if (bill[59] >= product?.price3) {
                                discount = product?.discount3;
                            } else if (bill[59] >= product?.price2) {
                                discount = product?.discount2;
                            } else if (bill[59] >= product?.price1) {
                                discount = product?.discount1;
                            }
                        }
                        let bonus = (discount * (bill[59] - product?.importPrice) * bill[55]) / 100;
                        let item = {
                            bonus: bonus,
                            discount: discount,
                            revenue: bill[60],
                            code: bill[49],
                            name: bill[51],
                            salePrice: bill[59],
                            time: bill[6],
                            branch: bill[0]
                        }
                        return item;

                    });
                    let totalRevenue = 0;
                    let totalBonus = 0;
                    listBillByStaff.forEach(item => {
                        totalBonus += item.bonus;
                        totalRevenue += item.revenue;
                    });
                    let staffData = {
                        name: staff.name,
                        bonus: formatNumber(totalBonus),
                        revenue: formatNumber(totalRevenue),
                        listBill: listBillByStaff
                    }
                    dataTable.push(staffData);
                    console.log("stafff", dataTable);
                })
                setData([...dataTable]);
                dispatch(setDataStaff([...dataTable]));
                dispatch(setDateUpdate(new Date().toLocaleDateString("vi-VN")));
                setLoading(false)
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    const handleChangeDate = (value, date) => {
        console.log("date", date);

        const from = value && value.length > 1 ? value[0].toDate() : null;
        const to = value && value.length > 1 ? value[1].toDate() : null;
        console.log("datteeeee", moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD"));
        setFromTime(from ? moment(from).format("YYYY-MM-DD") : null);
        setToTime(to ? moment(to).format("YYYY-MM-DD") : null);
    }
    useEffect(() => {
        if (reducer?.isRefresh) {
            getConfig();
        }
    }, [reducer])
    useEffect(() => {
        if (dataStaff) {
            setData([...dataStaff]);
        }
    }, [dataStaff])
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            console.log("vao day");
            dispatch(setRefreshPage(true));
            dispatch(setResetData());
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    return (
        <div className='container-home'>
            <div className='header-home'>
                <div className='header-content'>
                    <a href='/'>
                        <img
                            src={logo_fami}
                            style={{ width: 150, height: 140, marginLeft: 40 }}
                        />
                    </a>

                    <p className='title-header'>HỆ THỐNG TÍNH LƯƠNG CTV FAMI</p>

                </div>
                <div>
                        
                    
                        <a href='https://docs.google.com/spreadsheets/d/1C-gBVs2YU2YKElNegzfNWBNP8yGU-znIC0fb5-yzi7c/edit#gid=0' target='_blank'>Cấu hình</a>
                    

                </div>

            </div>
            <div className='button-import'>
                {/* <RangePicker
                    onChange={handleChangeDate}
                    format={dateFormat}
                    style={{marginRight: 20}}
                    /> */}
                {/* <Button type="primary" block icon={<CalculatorOutlined />} disabled={loading} onClick={() => handleGetDataFromKiot()}
                        style={{width: 200}}
                    >Tính toán dữ liệu</Button>     */}
                <Upload {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="primary" block icon={<ImportOutlined />} disabled={loading} onClick={() => { }}
                        style={{ width: 200, }}
                    >
                        Import dữ liệu
                    </Button>
                </Upload>

            </div>
            <p style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}>Kết quả</p>
            <Table columns={columns} dataSource={data} style={{ marginTop: 20, marginLeft: 40, marginRight: 40 }} />
            <div className='header-download'>
                        <a href='https://doanhso.baobifami.com/famictv_v1.apk' target='_blank'>
                            <img
                                src={download_android}
                                style={{ width: 150, height: 60 }}
                                width={150}
                                height={60}
                            />
                        </a>
                        <a href="itms-services://?action=download-manifest&amp;url=https://doanhso.baobifami.com/famictv_v1.plit">
                            <img
                                src={download_ios}
                                style={{ width: 150, height: 60, marginLeft: 20 }}
                                width={150}
                                height={60}
                            />
                        </a>
                    
                </div>
            {
                loading ?
                    <div className='loading_request'>
                        <Spin tip={message} size="large" />
                    </div> : null
            }
        </div>
    )
});

export default HomeScreen;