import { Button, Table } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo_fami from '../../assets/logo_fami.png';
import { formatNumber } from '../../helper/formatnumb';
import { BackwardOutlined } from '@ant-design/icons';
import '../detail/detail.css';
const DetailScreen = () => {
    const { state } = useLocation();
    const [data, setData] = useState(state?.listBill || []);
    let navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    }
    const columns = [
        {
            title: 'STT',
            key: 'index',
            render: (text, record) => data.indexOf(record) + 1,
        },
        {
            title: 'Mã SP',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Tên hàng hoá',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Chi nhánh',
            dataIndex: 'branch',
            key: 'branch'
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            key: 'time'
        },
        {
            title: 'Giá bán',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (text) => (
                <p>{formatNumber(text)}</p>
            )
        },
        {
            title: 'Doanh thu',
            dataIndex: 'revenue',
            key: 'revenue',
            render: (text) => (
                <p>{formatNumber(text)}</p>
            )
        },
        {
            title: 'Chiết khấu %',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Thu nhập',
            dataIndex: 'bonus',
            key: 'bonus',
            render: (text) => (
                <p>{formatNumber(text)}</p>
            )
        },

    ]
    return (
        <div>
            <div className='header-home'>
                <div className='header-content'>   
                <a href='/'>
                        <img
                            src={logo_fami}
                            style={{width: 150, height: 140, marginLeft: 40 }}
                        />
                    </a>
                    <p className='title-header'>HỆ THỐNG TÍNH LƯƠNG CTV FAMI</p>
                </div>
                
            </div>
            <div className='content-top'>
                <Button icon={<BackwardOutlined/>} onClick={() => handleBack()}>
                    Quay lại
                </Button>
                <p style={{ textAlign: 'center', color:  '#2b2e8b', fontSize: 20, fontWeight: 400, marginLeft: 40}}>Danh sách đơn hàng của: {state?.name} ({state?.listBill?.length} đơn hàng)</p>
            </div>
            
           <Table columns={columns} dataSource={data} style={{margin: 40}}/>
        </div>
    )
}

export default DetailScreen;